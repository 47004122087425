import { usePub, useSub } from "@/hooks";
import { Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWorkActions } from "../workByManagement/hooks/useWorkActions";
import { useWorkState } from "../workByManagement/hooks/useWorkState";

interface WorkOperationTableHeadProps {
  formReset?: boolean;
}

export const WorkSlittingTableHead = (params: WorkOperationTableHeadProps) => {
  const { formReset } = params;

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const publish = usePub();
  const state = useWorkState();
  const actions = useWorkActions();
  const { t } = useTranslation();

  useEffect(() => {
    setAllChecked(state.works.length === state.tempWorks.length);
  }, [state.works, state.tempWorks]);

  useSub("check", () => {
    setAllChecked(state.works.length === state.tempWorks.length);
  });

  useSub("unCheck", () => {
    setAllChecked(false);
  });

  useEffect(() => {
    if (formReset) {
      setAllChecked(false);
    }
  }, [formReset, allChecked]);

  return (
    <thead>
      <tr>
        <th></th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} c="black" ta="center" span>
              {t("work.id")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
            <Text fz="sm" fw={500} c="black" ta="center">
              {t("equipment.name")}
            </Text>
          </Flex>
        </th>
        <th>
          <Flex align="center" direction="column" wrap="wrap">
            <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
              <Text fz="sm" fw={500} c="black">
                {t("item.name")} ({t("item.code")})
              </Text>
              <Text fz="sm" fw={500} c="black" ta="center">
                {t("item.spec")}
              </Text>
            </Flex>
          </Flex>
        </th>
      </tr>
    </thead>
  );
};
