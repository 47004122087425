import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { usePostProductionPlanInputFromERPOutsourcingOrder } from "@/api/productionPlan/usePostProductionPlanGetQuery";
import { users } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { InvoiceStatementForm } from "@/features/invoiceStatement/form";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ExpectedDeliveryDateForm } from "@/features/operationOutsourceWorkByRow/components/expectedDeliveryDateForm";
import { WorkSlittingTableHead } from "@/features/outSourcingSlitting/workSlittingTableHead";
import {
  rowDataProps,
  WorkSlittingTableInRowBody,
} from "@/features/outSourcingSlitting/workSlittingTableInRowBody";
import { WorkSlittingTableInRowHead } from "@/features/outSourcingSlitting/workSlittingTableInRowHead";
import { WorkSlittingTableRow } from "@/features/outSourcingSlitting/workSlittingTableRow";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { useQueryString } from "@/features/standardLayout/useQueryString";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem, DefectTypeSet } from "@/features/ui/DefectTypeSet";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { OperationOutsourceNotFound } from "@/features/workByManagement/WorkNotFound";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import { WorkUpdateForm } from "@/features/workByManagement/WorkUpdateForm";
import useWorkMutation from "@/features/workByManagement/api/useWorkMutation";
import { workPage, workPageMutation } from "@/features/workByManagement/api/useWorkPageQuery";
import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import useAutoResetState from "@/features/workByManagement/hooks/useAutoResetState";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import useLocalStorage from "@/hooks/useLocalStorage";
import { useOutsourceWorkbyRowState } from "@/hooks/useReducerState";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import { StatusObjType } from "@/utils/workStatus";
import styled from "@emotion/styled";
import { Button, Chip, Flex, Menu, Pagination, Select } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  DefaultApiWmsFlushPutRequest,
  ProductionActionApiWorksMiscRecycleScrapPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
  WorksItemGet200ResponseRowsInner,
  WorksSlittingScrapDetailPost200ResponseInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar, IconSettings } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Work = {
  ActionBar: WorkActionBar,
  LeftActionBar: LeftActionBar,
  RightActionBar: RightActionBar,
  SiteSelect: WorkSiteSelect,
  Select: Select,
  Button: Button,
  WorkHeader: WorkHeader,
  WorkTableActionBar: WorkTableActionBar,
  WorkTableWrapper: WorkTableWrapper,
  WorkProductionPlanTable: WorkProductionPlanTable,
  WorkTable: WorkTable,
  WorkTableHead: WorkTableHead,
  WorkTableBody: WorkTableBody,
  WorkTableRow: WorkTableRow,
  WorkSlittingTableHead: WorkSlittingTableHead,
  WorkSlittingTableRow: WorkSlittingTableRow,
  WorkSlittingTableInRowHead: WorkSlittingTableInRowHead,
  WorkSlittingTableInRowBody: WorkSlittingTableInRowBody,
};

const useableOutsourceErpWorkOrder = customFunctions.ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER;
const useableOutsourceErpWorkRecord = customFunctions.ADD_ERP_WORK_RECORD_OUTSOURCING;

export const trackingStatusObj: StatusObjType = {
  WAITING: "대기",
  PAUSED: "일시정지",
  WORKING: "진행중",
  DONE: "완료",
};

export const ProductionOutsourcingSlittingPage = () => {
  const state = useWorkState();
  const actions = useWorkActions();
  const { resetAllChecked, setCheckedState, setWorkData } = useCheckboxContext();
  const checkArrangementRef = useRef(state.erpWorkData);
  const [activePage, setPage] = useState(1);
  const [equipmentCode, setEquipmentCode] = useState<string | null>(null);
  const [itemCode, setItemCode] = useState<string | null>(null);
  const [matchedData, setMatchedData] = useState<
    WorksSlittingScrapDetailPost200ResponseInner[] | undefined
  >([]); // matchedData를 상태로 관리
  const [slittingData, setSlittingData] =
    useState<AxiosResponse<WorksSlittingScrapDetailPost200ResponseInner[], any>>();
  const [startDate, setStartDate] = useQueryString<Date | null>("startDate", state.date[0]);
  const [endDate, setEndDate] = useQueryString<Date | null>("endDate", state.date[1]);
  const { state: workRowState, toggle } = useOutsourceWorkbyRowState();
  const { openModal } = useModal();
  const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [
    [],
    [],
  ]);
  // useAutoResetState를 사용하여 상태를 관리
  const [updateSuccess, setUpdateSuccess] = useAutoResetState<boolean>(false, 50);
  const publish = usePub();
  const {
    updateQuantityMutate,
    updateDefectMutate,
    deleteMutate,
    putEquipmentCorrectionMutate,
    workStartMutation,
    workEndMutation,
    workSlittingMutation,
  } = useWorkMutation();
  const { loading, setLoading, LoadingOverlay } = useLoader();
  const { t } = useTranslation();
  // const { mutate : flushMutate } = useInventoryMutation("flush");
  const { mutateAsync: inputERPOutsourcingWorkOrder } =
    usePostProductionPlanInputFromERPOutsourcingOrder();
  const queryClient = useQueryClient();
  const { mutate: flushMutate } = useMutation(
    (params: DefaultApiWmsFlushPutRequest) =>
      mutateInventories.flush(params).mutationFn(params as DefaultApiWmsFlushPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        customAlert(t("workMessage.flushSuccess"), t("workButton.flush"), "info");
      },
      onError: (error) => {
        customAlert(t("workMessage.flushFail"), t("workButton.flush"), "red");
      },
    },
  );
  const { data: whoData } = useQuery(users.who({}));

  const { data, refetch } = useQuery(
    workPage.workItemForOutSource({
      defectCodes: (defectTypes && defectTypes[0]) ?? (defectTypes && defectTypes[1]),
      query: {
        $and: [
          {
            // 최근 작업일 기준으로 변경 (2024-07-31 jswon)
            lastWorkDate: {
              $between: getStartAndEndOfDateRange(startDate, endDate),
            },
            // scheduledAt: {
            //     $between: getStartAndEndOfDateRange(state.date[0], state.date[1])
            // }
          },
          {
            trackingStatus: Object.keys(workRowState).reduce(
              (
                result: ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum[],
                key: string,
              ) => {
                if (workRowState[key as keyof typeof workRowState]) {
                  result.push(
                    key as ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
                  );
                }
                return result;
              },
              [],
            ),
          },
          {
            routingOutsourceId: { $ne: null },
          },
          ...(equipmentCode
            ? [
                {
                  equipmentCode: {
                    $eq: equipmentCode,
                  },
                },
              ]
            : []),
          ...(itemCode
            ? [
                {
                  itemCode: {
                    $eq: itemCode,
                  },
                },
              ]
            : []),
          ...(whoData?.data?.partner?.code
            ? [
                {
                  partnerCode: {
                    $eq: whoData?.data?.partner?.code,
                  },
                },
              ]
            : []),
        ],
      },
      page: activePage,
      pageSize: 100,
      sort: "-id",
    }),
  );

  useEffect(() => {
    actions.setResetState();
  }, []);

  // 상태에 따라 rows 데이터를 업데이트 및 체크박스 초기화
  useEffect(() => {
    setWorkData(data?.rows);
    resetAllChecked();
  }, [data, workRowState]);

  useEffect(() => {
    const planWorks = data?.rows?.map((plan) => plan).flat() as WorksItemGet200ResponseRowsInner[];
    if (planWorks && planWorks.length > 0) {
      actions.setTempWorks((prevWorks) => [...prevWorks, ...planWorks]);
    }
  }, [data]);

  const handleUpdateQuantities = () => {
    const workRequests = state.works
      .map((work) => {
        const weightData = matchedData?.find((data) => data.workId === work.id)?.usedMaterialWeight;

        if (work.id && weightData) {
          return {
            workId: work.id,
            quantity: "1",
            consumeQuantity: weightData,
          };
        }
        return null; // 유효하지 않은 데이터는 제외
      })
      .filter(Boolean); // null 값을 제거

    if (workRequests.length > 0) {
      try {
        setLoading(true);
        workSlittingMutation(
          {
            worksGroupSlittingPutRequest: {
              workRequest: workRequests.filter(
                (
                  request,
                ): request is { workId: number; quantity: string; consumeQuantity: string } =>
                  request !== null,
              ), // 모든 workId와 quantity를 포함한 배열
            },
          },
          {
            onSuccess: () => {
              actions.setTempQuantity([]);
              setUpdateSuccess(true); // 성공적인 API 호출 후 상태 업데이트
              refetch(); // 데이터 다시 불러오기
              actions.setWorks((prevWorks) => []);
            },
          },
        );
      } catch (error) {
        alert(t("workMessage.updatedFail"));
      } finally {
        setLoading(false);
      }
    } else {
      alert(t("workMessage.noValidData"));
    }
  };

  const handleUpdateDefects = async (
    work:
      | ProductionPlansGet200ResponseRowsInnerWorksInner
      | AuthSignupPost201ResponseEquipmentWorksInner,
  ) => {
    const defectData = state.tempDefects.find(
      (data) => data.id === (work.id && work.id.toString()),
    );
    // Update one defect at a time
    const updateDefectSequentially = async (defect: { key: string; value: string }) => {
      try {
        if (work.id) {
          await updateDefectMutate({
            workId: work.id,
            worksWorkIdDefectPutRequest: {
              defectCode: defect.key,
              accQuantity: defect.value,
            },
          });
        }
      } catch (e: any) {
        console.error("Error updating defect: ", e);
        throw e; // 에러를 다시 throw하여 밖에서 처리할 수 있도록 합니다.
      }
    };

    if (work.id && defectData) {
      for await (const defect of defectData.defect) {
        try {
          // Await each update operation before continuing to the next one
          await updateDefectSequentially(defect);
        } catch (e: any) {
          if (e.response?.data?.message === "defectQuantity is greater than startQuantity") {
            alert(t("workMessage.defectSaveFail"));
            break; // 중단하고 나머지 업데이트를 취소합니다.
          }
          // Handle other unexpected errors
          alert(t("workMessage.error"));
          break; // Stop processing further updates in case of any error
        }
      }
      // Reset temporary defects and set update success state
      actions.setTempDefects([]);
      setUpdateSuccess(true);
      refetch();
    }
  };

  // 기타입고 처리
  const { mutate: scrap } = useMutation(
    (params: ProductionActionApiWorksMiscRecycleScrapPutRequest) =>
      workPageMutation
        .scrapPut(params)
        .mutationFn(params as ProductionActionApiWorksMiscRecycleScrapPutRequest | any),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["workPage"]);
        customAlert(t("workMessage.scrapSuccess"), t("workButton.scrap"), "info");
        setLoading(false);
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({
            error,
            errorContext: t("workButton.scrap"),
          }),
          t("workMessage.scrapFail"),
          "red",
        );
        setLoading(false);
      },
    },
  );

  // 그룹핑 (설비코드, 공정코드, 원자재, 생성시간)
  const groupedData = useMemo(() => {
    const map = new Map();
    data?.rows?.forEach((row) => {
      const operationCode = row.productionPlan?.routingsData?.[0]?.operationCode ?? "";
      const materialCode = row.productionPlan?.routingsData?.[0]?.routingBoms?.[0]?.itemCode;
      const createdAt = row.createdAt?.substring(0, 16);
      const key = `${row.equipmentCode}__${createdAt}__${operationCode}__${materialCode}`;

      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push(row);
    });

    return Array.from(map.entries()).map(([key, orders]) => {
      const rows = orders[0]; // 같은 그룹이니까 첫 번째 꺼 기준으로

      return {
        key,
        itemCode: rows.itemCode,
        itemName: rows.itemName,
        scheduleSeq: rows.scheduleSeq,
        spec: rows.spec,
        equipmentCode: rows.equipmentCode,
        equipmentName: rows.equipmentName,
        orders,
      };
    });
  }, [data]);

  const itemsPerPage = 10; // 한 페이지에 표시할 항목 수

  // 현재 페이지에 표시할 데이터 계산
  const paginatedGroupedData = useMemo(() => {
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return groupedData.slice(startIndex, endIndex);
  }, [groupedData, activePage]);

  useEffect(() => {
    if (paginatedGroupedData.length < itemsPerPage && (data?.totalPages ?? 0) > activePage) {
      setPage((prev) => prev + 1); // 다음 페이지 요청
    }
  }, [paginatedGroupedData, data, activePage]);

  return (
    <WorkExampleContainer>
      <LoadingOverlay />
      <Work.ActionBar>
        <Work.LeftActionBar>
          <Flex gap="sm" direction={"column"}>
            <Flex gap="sm">
              <EquipmentAutoComplete
                onChange={(value) => {
                  setEquipmentCode(value);
                }}
                placeholder={t("placeholder.select", { item: t("equipment.equipment") })}
              />
              <ItemAutoComplete
                onChange={(value) => {
                  setItemCode(value);
                }}
                placeholder={t("placeholder.select", { item: t("item.item") })}
                itemType="PRODUCT,SEMI_PRODUCT"
              />
              <Work.Button
                leftIcon={<IconCalendar />}
                onClick={() => {
                  openModal(
                    <Calendar dates={state.date} type="range" />,
                    null,
                    t("workButton.dateSelect"),
                    true,
                  ).then((value) => {
                    if (isCalendarDate(value)) {
                      actions.setDate(value);
                      setStartDate(value[0]);
                      setEndDate(value[1]);
                      resetAllChecked();
                    }
                  });
                }}
              >
                {t("workButton.dateSelect")}
              </Work.Button>
            </Flex>
          </Flex>
        </Work.LeftActionBar>
        <Work.RightActionBar>
          <Menu shadow="md" width={150} position="bottom-end">
            <Menu.Target>
              <Button color="gray" rightIcon={<IconSettings />}>
                Settings
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  openModal(<DefectTypeSet />, null, t("workButton.defectSetting")).then(
                    (result) => {
                      // result 데이터가 특정 데이터일 경우 불량 타입 재조회 (불량 유형 설정을 완료했다는 의미)
                      if (Array.isArray(result)) {
                        setDefectTypes(result as DefectTypeItem[][]);
                      }
                    },
                  );
                }}
              >
                Settings
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Work.RightActionBar>
      </Work.ActionBar>
      <Work.WorkHeader dateType="range" startDate={startDate} endDate={endDate} />
      <Work.WorkTableActionBar>
        <Work.LeftActionBar>
          <Work.Button
            variant="light"
            disabled={!(state.works && state.works.length === 1)}
            onClick={() => {
              const [work] = state.works;
              openModal(
                <WorkInputForm<"plan"> workRowData={work as WorksItemGet200ResponseRowsInner} />,
                null,
                t("workButton.materialInput"),
                true,
              );
            }}
          >
            {t("workButton.materialInput")}
          </Work.Button>
          <Work.Button
            variant="light"
            color="orange"
            disabled={!(state.works && state.works.length === 1)}
            onClick={() => {
              const [work] = state.works;
              flushMutate({
                wmsFlushPutRequest: {
                  locationCode:
                    ((work as WorksItemGet200ResponseRowsInner)?.targetLocationCode ?? "") ||
                    ((work as WorksItemGet200ResponseRowsInner)?.equipment?.targetLocationCode ??
                      "") ||
                    ((work as WorksItemGet200ResponseRowsInner)?.routingData?.operation
                      ?.toLocationCode ??
                      ""),
                },
              });
            }}
          >
            {t("workButton.flush")}
          </Work.Button>

          <Work.Button
            variant="light"
            color="teal"
            disabled={!(state.works && state.works.length === 1)}
            onClick={async () => {
              const [work] = state.works as WorksItemGet200ResponseRowsInner[];
              if (work.id && work.equipmentCode && work.trackingStatus === "WORKING") {
                const result = await putEquipmentCorrectionMutate({
                  equipmentsCorrectionPutRequest: {
                    // equipmentCode: work.equipmentCode
                    workId: work.id,
                  },
                });
                result.data.length
                  ? customAlert(
                      t("workMessage.correctionSuccess"),
                      t("workButton.correction"),
                      "info",
                    )
                  : customAlert(t("workMessage.correctionFail"), t("workButton.correction"), "red");
              } else {
                customAlert(t("workMessage.correctionFail2"), t("workButton.correction"), "red");
              }
            }}
          >
            {t("workButton.correction")}
          </Work.Button>
          {customFunctions.ADD_OUTSOURCING_PRODUCTION_PLAN_BUTTON_AND_EXPECTED_DELIVERY_DATE && (
            <>
              <Work.Button
                disabled={
                  !(
                    state.works &&
                    state.works.length === 1 &&
                    (state.works[0] as rowDataProps).slittingData
                  )
                }
                onClick={() => {
                  const works = state.works;
                  workStartMutation(
                    {
                      workId: works?.[0].id ?? 0,
                      worksWorkIdTrackingStartPostRequest: {
                        workId: works?.[0].id ?? 0,
                      },
                    },
                    {
                      onSuccess: () => {
                        customAlert(
                          t("workMessage.workStart"),
                          t("workStatus.timeTrackingStart"),
                          "info",
                        );
                        queryClient.invalidateQueries(["workPage"]);
                      },
                      onError: (error) => {
                        customAlert(
                          handleErrorResponse({
                            error,
                            errorContext: t("workStatus.timeTrackingStart"),
                          }),
                          t("workMessage.workStartFail"),
                          "red",
                        );
                      },
                    },
                  );
                }}
              >
                {t("workStatus.timeTrackingStart")}
              </Work.Button>
              <Work.Button
                color="red"
                disabled={
                  !(
                    state.works &&
                    state.works.length === 1 &&
                    (state.works[0] as rowDataProps).slittingData
                  )
                }
                onClick={() => {
                  const works = state.works;
                  workEndMutation(
                    {
                      workId: works?.[0].id ?? 0,
                      worksWorkIdTrackingStartPostRequest: {
                        workId: works?.[0].id ?? 0,
                      },
                    },
                    {
                      onSuccess: () => {
                        customAlert(
                          t("workMessage.workEnd"),
                          t("workStatus.timeTrackingEnd"),
                          "info",
                        );
                        queryClient.invalidateQueries(["workPage"]);
                      },
                      onError: (error) => {
                        customAlert(
                          handleErrorResponse({
                            error,
                            errorContext: t("workStatus.timeTrackingEnd"),
                          }),
                          t("workMessage.workEndFail"),
                          "red",
                        );
                      },
                    },
                  );
                }}
              >
                {t("workStatus.timeTrackingEnd")}
              </Work.Button>
              <Work.Button
                color="green"
                disabled={!(state.works && state.works.length === 1)}
                onClick={() => {
                  const slitting = matchedData?.find((data) => data.workId === state.works[0].id);

                  openModal(
                    <InvoiceStatementForm
                      data={state.works[0] as WorksItemGet200ResponseRowsInner}
                      slittingData={slitting}
                    />,
                    null,
                    t("invoice.invoice"),
                  );
                }}
              >
                {t("invoice.invoice")}
              </Work.Button>
              <Work.Button
                color="grape"
                disabled={!(state.works && state.works.length === 1)}
                onClick={() => {
                  openModal(
                    <ExpectedDeliveryDateForm workData={state.works} />,
                    null,
                    t("workButton.delivery"),
                  );
                }}
              >
                {t("workButton.delivery")}
              </Work.Button>
              <Work.Button
                color="grape"
                disabled={!(state.scrapWorkIds && state.scrapWorkIds.length >= 1)}
                onClick={() => {
                  scrap({
                    worksMiscRecycleScrapPutRequest: {
                      workIds: state.scrapWorkIds,
                    },
                  });
                }}
              >
                {t("workButton.scrap")}
              </Work.Button>
            </>
          )}
        </Work.LeftActionBar>
        <Work.RightActionBar>
          <Work.Button
            color="yellow"
            disabled={!(state.works && state.works.length === 1)}
            onClick={() => {
              const works = state.works;
              openModal(
                <WorkUpdateForm<"plan">
                  workRowData={works as ProductionPlansGet200ResponseRowsInnerWorksInner[]}
                />,
                null,
                `${t("work.work")} ${t("workButton.updated")}`,
                true,
              ).then((result) => {
                if (result === true) {
                  actions.setWorks((prevWorks) => []);
                  actions.setTempDefects([]);
                  setUpdateSuccess(true);
                  refetch();
                }
              });
            }}
          >
            {t("workButton.updated")}
          </Work.Button>
          <Work.Button
            color="red"
            disabled={!(state.works && state.works.length >= 1)}
            onClick={() => {
              if (window.confirm(t("workMessage.delete"))) {
                state.works.forEach((work) => {
                  work.id && deleteMutate({ workId: work.id });
                });
              }
            }}
          >
            {t("workButton.delete")}
          </Work.Button>
          <Work.Button
            disabled={!(state.scrapWorkIds && state.scrapWorkIds.length >= 1)}
            onClick={() => {
              handleUpdateQuantities();
              // state.works.forEach((work) => {
              // await handleUpdateDefects(work);
              // });
            }}
          >
            {t("workButton.save")}
          </Work.Button>
        </Work.RightActionBar>
      </Work.WorkTableActionBar>
      <Work.WorkTableActionBar>
        <Work.LeftActionBar></Work.LeftActionBar>
        <Work.RightActionBar>
          <ChipsBox>
            {Object.keys(trackingStatusObj).map((key) => (
              <Chip
                variant="filled"
                size="md"
                radius="sm"
                key={key}
                checked={workRowState[key] || false}
                onClick={() => {
                  toggle(key);
                  actions.setWorks((prevWorks) => []);
                }}
              >
                {t(trackingStatusObj[key])}
              </Chip>
            ))}
          </ChipsBox>
        </Work.RightActionBar>
      </Work.WorkTableActionBar>
      <Work.WorkTableWrapper>
        <Work.WorkProductionPlanTable>
          <Work.WorkSlittingTableHead formReset={updateSuccess} />
          <tbody>
            {paginatedGroupedData && paginatedGroupedData.length ? (
              paginatedGroupedData.map((group, groupIdx) => {
                const {
                  itemCode,
                  itemName,
                  scheduleSeq,
                  equipmentCode,
                  equipmentName,
                  spec,
                  orders,
                } = group;

                return (
                  <Work.WorkSlittingTableRow
                    rowData={{
                      itemCode,
                      itemName,
                      scheduleSeq,
                      equipmentCode,
                      equipmentName,
                      spec,
                    }}
                    key={groupIdx}
                    defectTypes={defectTypes && defectTypes[1]}
                    formReset={updateSuccess}
                    workIds={orders.map((order: any) => order.id)}
                  >
                    {(slittingData) => {
                      setMatchedData(slittingData);
                      return (
                        <tr>
                          <Td colSpan={9} style={{ padding: "0" }}>
                            <Work.WorkTable isWithBorder={false}>
                              <Work.WorkTableBody>
                                <Work.WorkSlittingTableInRowHead
                                  slittingData={slittingData}
                                  rowData={group}
                                  workIds={orders.map((order: any) => order.id)}
                                />
                                {orders.map((work: any, index: number) => {
                                  const slitting = slittingData?.find(
                                    (slitting) => slitting.workId === work.id,
                                  );
                                  return (
                                    <Work.WorkSlittingTableInRowBody
                                      key={work.id}
                                      rowData={work}
                                      rowIndex={groupIdx}
                                      formReset={updateSuccess}
                                      slittingData={slitting}
                                    />
                                  );
                                })}
                              </Work.WorkTableBody>
                            </Work.WorkTable>
                          </Td>
                        </tr>
                      );
                    }}
                  </Work.WorkSlittingTableRow>
                );
              })
            ) : (
              <OperationOutsourceNotFound />
            )}
          </tbody>
        </Work.WorkProductionPlanTable>
      </Work.WorkTableWrapper>
      <Pagination
        onChange={(page) => {
          resetAllChecked();
          setPage(page);
        }}
        value={activePage}
        total={Math.ceil(groupedData.length / itemsPerPage)}
        size="lg"
        radius="sm"
      />
    </WorkExampleContainer>
  );
};

const WorkExampleContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center; // flex-start;
  gap: 8px;
`;

const Td = styled.td<{ minWidth?: number; width?: number; isNumber?: boolean }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

const StatusChip = styled(Chip)`
  label {
    min-width: 2rem;
    min-height: 2rem;
    height: 2.4rem;
    font-size: 1rem;
    color: gray;
  }
`;
