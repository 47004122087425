import { theme } from "@/styles/theme";
import { Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  ProductionActionApiWorksSlittingScrapDetailPostRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksItemGet200ResponseRowsInner,
  WorksSlittingScrapDetailPost200ResponseInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";
import { workPageMutation } from "../workByManagement/api/useWorkPageQuery";
import { useCheckboxContext } from "../workByManagement/context/CheckboxProvider";
import { SocketData } from "../workByManagement/context/WorkProvider";
import { useWorkActions } from "../workByManagement/hooks/useWorkActions";
import { useWorkState } from "../workByManagement/hooks/useWorkState";
import { WorkEquipmentCell } from "../workByManagement/WorkEquipmentCell";
import { WorkNumberInputCell } from "../workByManagement/WorkNumberInputCell";
import { Td, WorkTr } from "../workByManagement/WorkTableRow";

interface WorkOperationProps {
  rowData: WorksItemGet200ResponseRowsInner;
  socketData?: SocketData;
  socket?: any;
  defectTypes: DefectTypeItem[] | null;
  bgColor?: string;
  formReset: boolean;
  children?: (slittingData?: WorksSlittingScrapDetailPost200ResponseInner[]) => React.ReactNode;
  workIds?: number[];
}

// 작업지시 Row의 작업 상태별 색상 정의
const workStatusColors = {
  WAITING: "",
  WORKING: theme.colors?.green?.[0],
  PAUSED: theme.colors?.orange?.[0],
  DONE: theme.colors?.gray?.[4],
};

const defaultWorkStatusColor = theme.colors?.gray?.[4];

export const WorkSlittingTableRow = (params: WorkOperationProps) => {
  const { rowData, defectTypes, bgColor, formReset, children, workIds } = params;
  const state = useWorkState();
  const actions = useWorkActions();
  const { areAllChecked } = useCheckboxContext();
  const [slittingData, setSlittingData] =
    useState<AxiosResponse<WorksSlittingScrapDetailPost200ResponseInner[], any>>();
  const trackingStatus = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
    .trackingStatus;

  const [opened, { toggle, open }] = useDisclosure(false);

  const handleDefectChange = ({ key, value }: { key: string; value: string }) => {
    const workData = state.tempDefects.find(
      (defect) => defect.id === (rowData.id && rowData?.id.toString()),
    );
    const data = workData?.defect.find((defect) => defect.key === key);
    if (data) {
      data.value = value;
    } else {
      workData
        ? workData.defect.push({ key, value })
        : state.tempDefects.push({
            id: rowData.id ? rowData.id.toString() : undefined,
            defect: [{ key, value }],
          });
    }
    actions.setTempDefects(state.tempDefects);
  };

  const { mutate: slittingPost } = useMutation(
    (params: ProductionActionApiWorksSlittingScrapDetailPostRequest) =>
      workPageMutation
        .slitting(params)
        .mutationFn(params as ProductionActionApiWorksSlittingScrapDetailPostRequest | any),
    {
      onSuccess: (data) => {
        setSlittingData(data);
      },
    },
  );

  useEffect(() => {
    if (opened) {
      slittingPost({
        worksMiscRecycleScrapPutRequest: {
          workIds: workIds,
        },
      });
    }
  }, [opened]);

  return (
    <>
      <WorkTr
        color={
          rowData.id && trackingStatus ? workStatusColors[trackingStatus] : defaultWorkStatusColor
        }
      >
        <Td minWidth={5} width={8}>
          <Text ta="center">
            {!opened && (
              <IconPlus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
            {opened && (
              <IconMinus onClick={toggle} style={{ verticalAlign: "middle", cursor: "pointer" }} />
            )}
          </Text>
        </Td>
        <Td minWidth={5} width={8}>
          <Text ta="right">{rowData.scheduleSeq}</Text>
        </Td>
        <Td minWidth={12} width={20}>
          <WorkEquipmentCell
            data={{ equipmentCode: rowData.equipmentCode, equipmentName: rowData.equipmentName }}
          />
        </Td>
        <Td minWidth={22} width={31}>
          <Flex direction="row" align="center" justify="space-between">
            <Text>
              {rowData?.itemName}({rowData?.itemCode})
            </Text>
            <Text>{rowData?.spec}</Text>
          </Flex>
        </Td>
        {defectTypes?.map((defectType: DefectTypeItem) => (
          <Td key={defectType.value} minWidth={8} width={14}>
            {rowData?.id ? (
              <WorkNumberInputCell
                data={{
                  key: defectType.value,
                  defaultValue:
                    (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "0",
                  value:
                    (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "",
                  onChange: handleDefectChange,
                  formReset: formReset ?? false,
                }}
              />
            ) : null}
          </Td>
        ))}
      </WorkTr>
      {opened && typeof children === "function" && children(slittingData?.data)}
    </>
  );
};
