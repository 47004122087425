import { useCheckboxContext } from "@/features/workByManagement/context/CheckboxProvider";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Table, Text, TextInput } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksItemGet200ResponseRowsInner,
  WorksSlittingScrapDetailPost200ResponseInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWorkActions } from "../workByManagement/hooks/useWorkActions";
import { useWorkState } from "../workByManagement/hooks/useWorkState";
import { Td } from "../workByManagement/WorkTableRow";
import { WorkSlittingProgressCell } from "./workSlittingProgressCell";

export interface rowDataProps extends WorksItemGet200ResponseRowsInner {
  slittingData: {
    workId: number;
    scrapWeight: string;
    widthWithScrap: string;
    widthWithoutScrap: string;
    usedMaterialWeight: string;
  };
}
interface WorkOperationTableRowInRowProps {
  rowData: WorksItemGet200ResponseRowsInner;
  rowIndex: number;
  formReset: boolean;
  slittingData: WorksSlittingScrapDetailPost200ResponseInner | undefined;
}
const workStatusColors = {
  WAITING: "",
  WORKING: theme.colors?.green?.[0],
  PAUSED: theme.colors?.orange?.[0],
  DONE: theme.colors?.gray?.[4],
};
const defaultWorkStatusColor = theme.colors?.gray?.[4];
export const WorkSlittingTableInRowBody = (params: WorkOperationTableRowInRowProps) => {
  const { rowData, rowIndex, formReset, slittingData } = params;
  const state = useWorkState();
  const actions = useWorkActions();
  const { areAllChecked } = useCheckboxContext();
  const checkRef = useRef<HTMLInputElement>(null);
  const [checkedValues, setCheckedValues] = useState(false);
  const publish = usePub();
  const { t } = useTranslation();

  useSub("checkAll", () => {
    setCheckedValues(true);
  });

  useSub("unCheckAll", () => {
    setCheckedValues(false);
  });

  useEffect(() => {
    areAllChecked();
  }, [areAllChecked()]);

  useEffect(() => {
    if (formReset) {
      setCheckedValues(false);
    }
  }, [formReset]);

  useEffect(() => {
    const id = rowData?.id;
    if (!id) return;

    const isChecked = state.scrapWorkIds?.includes(id);

    actions.setWorks((prevWorks) => {
      const exists = prevWorks.some((work) => work?.id === id);

      if (isChecked && !exists) {
        // 체크된 상태인데 아직 없으면 추가
        return [...prevWorks, rowData];
      }

      if (!isChecked && exists) {
        // 체크 해제된 상태인데 리스트에 있으면 제거
        setCheckedValues(false);
        return prevWorks.filter((work) => work?.id !== id);
      }

      return prevWorks; // 변화 없을 경우 그대로 반환
    });
  }, [state.scrapWorkIds, rowData?.id]);

  return (
    <WorkRowTr>
      <Td colSpan={9}>
        <Table withColumnBorders withBorder>
          <thead>
            <WorkRowTr>
              <th></th>
              <th>{t("workLog.workId")}</th>
              <th>{t("work.progress")}</th>
              <th>
                {t("work.widthWithScrap")} / {t("work.widthWithoutScrap")} / {t("work.scrapWeight")}
              </th>
              <th>{t("summary.todoQuantity")}</th>
            </WorkRowTr>
          </thead>
          <tbody>
            <WorkRowTr
              color={
                rowData.id && rowData.trackingStatus
                  ? workStatusColors[rowData.trackingStatus]
                  : defaultWorkStatusColor
              }
            >
              <Td minWidth={4} width={4}>
                <Flex align="center" justify="center">
                  <Checkbox
                    ref={checkRef}
                    checked={state.scrapWorkIds?.includes(rowData?.id ?? 0) || checkedValues}
                    onChange={(e) => {
                      if (e.target.checked) {
                        publish("check");
                        setCheckedValues(true);
                        actions.setWorks(
                          (
                            prevWorks: (
                              | AuthSignupPost201ResponseEquipmentWorksInner
                              | ProductionPlansGet200ResponseRowsInnerWorksInner
                            )[],
                          ) => [...prevWorks, rowData],
                        );
                      } else {
                        publish("unCheck");
                        setCheckedValues(false);
                        actions.setWorks(
                          (
                            prevWorks: (
                              | AuthSignupPost201ResponseEquipmentWorksInner
                              | ProductionPlansGet200ResponseRowsInnerWorksInner
                            )[],
                          ) => prevWorks.filter((work) => work?.id !== rowData?.id),
                        );
                      }
                    }}
                  />
                </Flex>
              </Td>
              <Td minWidth={8} width={8}>
                {rowData.id}
              </Td>
              <Td minWidth={8} width={24}>
                <WorkSlittingProgressCell
                  data={{
                    itemName: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                      ?.name as string,
                    itemCode: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                      ?.code as string,
                    itemId: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                      ?.id as number,
                    itemSpec: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item
                      ?.spec as string,
                    workId: rowData.id,
                    routingCode: rowData.routingCode,
                    trackingStatus: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
                      ?.trackingStatus,
                    purchaseOrderItem: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)
                      ?.purchaseOrderItem,
                  }}
                />
              </Td>
              <Td minWidth={8} width={16} style={{ textAlign: "right" }}>
                {slittingData?.scrapWeight ? (
                  <Text>
                    {Number(slittingData?.scrapWeight) + Number(slittingData.usedMaterialWeight)} /{" "}
                    {slittingData?.usedMaterialWeight} / {slittingData?.scrapWeight}{" "}
                  </Text>
                ) : (
                  <Text>{t("workMessage.scrapNone")}</Text>
                )}
              </Td>
              <Td minWidth={8} width={14}>
                {/* slittingData?.usedMaterialWeight */}
                <TextInput value={1} styles={{ input: { textAlign: "right" } }} />
              </Td>
            </WorkRowTr>
          </tbody>
        </Table>
      </Td>
    </WorkRowTr>
  );
};

// WorkRow 영역
const WorkRowTr = styled.tr`
  .mantine-1r8slib > thead > tr > th {
    background-color: ${theme.colors?.gray?.[0]};
    color: ${theme.colors?.gray?.[9]};
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
  }
  td {
    background-color: ${(props) => props.color};
  }
`;
