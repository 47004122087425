import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { TextEllipsis } from "@/features/workByManagement/WorkTableRow";
import { theme } from "@/styles/theme";
import { trackingStatusColor } from "@/utils/workStatus";
import { Badge, Flex, Tooltip } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

interface WorkEquipmentCellProps {
  data: {
    itemName?: string;
    itemCode?: string;
    itemId?: number;
    itemSpec?: string;
    workId?: number;
    routingCode?: string;
    trackingStatus?: ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;
    purchaseOrderItem?: AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
  };
}

export const WorkSlittingProgressCell = ({ data }: WorkEquipmentCellProps) => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  const {
    itemName,
    itemCode,
    itemId,
    itemSpec,
    workId,
    routingCode,
    trackingStatus,
    purchaseOrderItem,
  } = data;

  if (!itemId) {
    return null;
  }

  return (
    <Flex direction="column" gap="md">
      {/* <WorkLogProgressBar workId={workId} /> */}
      <Flex gap="md">
        <Flex direction="column" gap="xs">
          <Badge
            variant="outline"
            color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}
          >
            {t(
              trackingStatus as ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
            )}
          </Badge>
          {purchaseOrderItem ? (
            <Badge color="violet" variant="outline">
              {t("purchaseOrder.purchaseOrder")}
            </Badge>
          ) : null}
        </Flex>
        <DetailLink
          onClick={() => {
            openModal(<WorkView workId={workId} />, null, t("work.workOrder"));
          }}
        >
          <Tooltip
            label={
              <Flex direction="column">
                <TextEllipsis fz="sm" fw={700}>
                  {routingCode}
                </TextEllipsis>
                <TextEllipsis fz="sm" fw={700}>
                  {itemName} ({itemCode})
                </TextEllipsis>
                <TextEllipsis fz="sm" fw={700}>
                  {itemSpec ?? "-"}
                </TextEllipsis>
              </Flex>
            }
          >
            <Flex direction="column">
              <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>
                {routingCode}
              </TextEllipsis>
              <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>
                {itemName} ({itemCode})
              </TextEllipsis>
              <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>
                {itemSpec ?? "-"}
              </TextEllipsis>
            </Flex>
          </Tooltip>
        </DetailLink>
      </Flex>
    </Flex>
  );
};
