import { mutatePartners } from "@/api/partner/usePartnerQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  MasterApiPartnersPostRequest,
  PartnersGet200ResponseRowsInnerTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface PartnerCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const PartnerCreateForm = (params: PartnerCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiPartnersPostRequest) =>
      mutatePartners.create(params).mutationFn(params as MasterApiPartnersPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partners"]);
        closeModal(form.values);
        customAlert(
          t("message.createSuccess", { item: t("partner.partner") }),
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("partner.code") }),
          t("common.createFail", { item: t("partner.partner") }),
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
      name: undefined,
      contact: undefined,
      type: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        partnersGetRequest: {
          code: form.values.code!,
          name: form.values.name!,
          contact: form.values.contact!,
          type: form.values.type!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("partner.code") }),
        t("common.createFail", { item: t("partner.partner") }),
        "red",
      );
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label={t("partner.code")}
            placeholder={t("placeholder.input", { item: `${t("partner.code")}` })}
            {...form.getInputProps("code")}
          />
          <TextInput
            withAsterisk
            label={t("partner.name")}
            placeholder={t("placeholder.input", { item: `${t("partner.name")}` })}
            {...form.getInputProps("name")}
          />
          <TextInput
            type="number"
            label={t("partner.contact")}
            withAsterisk
            placeholder={t("placeholder.input", { item: `${t("partner.contact")}` })}
            {...form.getInputProps("contact")}
          />
          <Select
            withAsterisk
            label={t("partner.type")}
            placeholder={t("placeholder.select", { item: `${t("partner.type")}` })}
            {...form.getInputProps("type")}
            data={[
              { value: PartnersGet200ResponseRowsInnerTypeEnum.SALES, label: t("user.SALES") },
              {
                value: PartnersGet200ResponseRowsInnerTypeEnum.PURCHASE,
                label: t("user.PURCHASE"),
              },
              {
                value: PartnersGet200ResponseRowsInnerTypeEnum.BI_DIRECTION,
                label: t("user.BI_DIRECTION"),
              },
              {
                value: PartnersGet200ResponseRowsInnerTypeEnum.OUTSOURCING,
                label: t("user.OUTSOURCING"),
              },
            ]}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "partner",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("partner.partner")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
