import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setStatusIcon, statusObj } from "@/utils/workStatus";
import styled from "@emotion/styled";
import { Text, ThemeIcon, Timeline } from "@mantine/core";
import {
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { WorkViewProps } from "./WorkView";
export const WorkStatusTimeLine = ({ workId }: WorkViewProps) => {
  const { t } = useTranslation();
  const { data: workLogsData } = useQuery(
    workLogs.find({
      query: {
        $and: [
          { workId: { $eq: workId ?? 0 } },
          {
            workLogType: [
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME,
            ],
          },
        ],
      },
      sort: "-id",
      populate: ["downtimeReasonName"],
    }),
  );

  const calculateWorkLogDurations = (data: WorkLogsGet200ResponseRowsInner[] | undefined) => {
    const result = [...(data ?? [])] as (WorkLogsGet200ResponseRowsInner & {
      formattedTime?: string;
    })[];

    let endIndex = 0;
    let timeDifference = 0;

    for (let i = 0; i < result.length; i++) {
      const isTimeTrackingStart =
        result[i].workLogType ===
        WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START;
      const isTimeTrackingEnd =
        result[i].workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END;

      if (isTimeTrackingStart) {
        timeDifference =
          new Date(result[endIndex].createdAt as string).getTime() -
          new Date(result[i].createdAt as string).getTime();

        const diffSeconds = Math.round((timeDifference ?? 0) / 1000);
        const time = `(소요시간: ${diffSeconds}초)`;

        result[endIndex].formattedTime = time;
      } else if (isTimeTrackingEnd) {
        endIndex = i;
      }
    }

    return result;
  };

  return (
    <WorkStatusTimeLineWrapper>
      <Timeline active={0} bulletSize={24} lineWidth={2}>
        {calculateWorkLogDurations(workLogsData?.data ?? []).map((log, index) => {
          const { icon: IconComponent, color } = setStatusIcon(log.workLogType!);
          return (
            <Timeline.Item
              key={index}
              bullet={
                <ThemeIcon color={color} size={24} radius="xl">
                  <IconComponent size={16} />
                </ThemeIcon>
              }
              title={`${t(statusObj[log.workLogType!])} ${log.downtimeReasonName ? `(${log.downtimeReasonName})` : ""} ${
                log.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END
                  ? log.formattedTime
                  : ""
              }`}
            >
              {/* Timeline의 내용은 실제 로그 데이터를 참조하여 동적으로 생성합니다. */}
              <Text size="xs" mt={4}>
                {setDateFormatStringWithTime(log.createdAt)}{" "}
              </Text>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </WorkStatusTimeLineWrapper>
  );
};

const WorkStatusTimeLineWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
