import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Table } from "@mantine/core";
import {
  WorksItemGet200ResponseRowsInner,
  WorksSlittingScrapDetailPost200ResponseInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useWorkActions } from "../workByManagement/hooks/useWorkActions";

interface WorkOperationTableRowInRowProps {
  slittingData: WorksSlittingScrapDetailPost200ResponseInner[] | undefined;
  rowData: WorksItemGet200ResponseRowsInner;
  workIds?: number[];
}
export const WorkSlittingTableInRowHead = (props: WorkOperationTableRowInRowProps) => {
  const { slittingData, rowData, workIds } = props;
  const actions = useWorkActions();

  return (
    <WorkRowTr>
      <Table withBorder withColumnBorders>
        <thead>
          <WorkRowTr>
            <th>
              <Flex justify={"center"}>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      actions.setScrapWorkIds(workIds);
                    } else {
                      actions.setScrapWorkIds(workIds?.filter((id) => id === rowData.id));
                    }
                  }}
                />
              </Flex>
            </th>
            <th>원자재 로트이름</th>
            <WorkRowTd>{slittingData?.[0]?.materialLotName}</WorkRowTd>
            <th>폭</th>
            <WorkRowTd>{slittingData?.[0]?.materialWidth}</WorkRowTd>
            <th>무게</th>
            <WorkRowTd>{slittingData?.[0]?.materialWeight}</WorkRowTd>
          </WorkRowTr>
        </thead>
      </Table>
    </WorkRowTr>
  );
};

const WorkRowTr = styled.tr`
  .mantine-1r8slib > thead > tr > th {
    background-color: ${theme.colors?.gray?.[0]};
    color: ${theme.colors?.gray?.[9]};
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
  }
`;

const WorkRowTd = styled.td`
  background-color: white;
  color: ${theme.colors?.gray?.[9]};
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
`;
