import { abstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { defects } from "@/api/defect/useDefectsQuery";
import { department } from "@/api/department/useDepartmentQuery";
import { downtimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { equipments } from "@/api/equipment/useEquipmentQuery";
import { grades } from "@/api/grade/useGradesQuery";
import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { inventories } from "@/api/inventories/useInventoriesQuery";
import { invoice } from "@/api/invoiceStatement/useInvoiceQuery";
import { itemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { locations } from "@/api/locations/useLocationsQuery";
import { lots } from "@/api/lots/useLotsQuery";
import { molds } from "@/api/mold/useMoldQuery";
import { moldStocks } from "@/api/moldStock/useMoldStockQuery";
import { operations } from "@/api/operations/useOperationsQuery";
import { partners } from "@/api/partner/usePartnerQuery";
import { purchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { routings } from "@/api/routing/useRoutingQuery";
import { routingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { sites } from "@/api/sites/useSitesQuery";
import { stockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { transportations } from "@/api/transportation/useTransportationQuery";
import { users } from "@/api/users/useUsersQuery";
import { wiWithEsl } from "@/api/wiWithEslView/useWiWithEslViewQuery";
import { wipInventory } from "@/api/wipInventories/useWipInventoriesQuery";
import { workLogsWorks } from "@/api/workLogsWorks/useWorkLogsWorksQuery";
import { customFunctions } from "@/config/customFunction";
import {
  abstractItemsHeader,
  defectsHeader,
  departmentHeader,
  downtimeReasonsHeader,
  equipmentsHeader,
  gradesHeader,
  inventoriesHeader,
  invoiceStatementHeader,
  itemCategoriesHeader,
  itemsBomHeader,
  itemsHeader,
  LedgersHeader,
  locationsHeader,
  lotsHeader,
  moldsHeader,
  moldStocksHeader,
  operationsHeader,
  orderHeader,
  partnersHeader,
  purchaseOrderHeader,
  purchaseOrderItemsHeader,
  routingOutsourcesHeader,
  routingsHeader,
  sitesHeader,
  specificationsHeader,
  transportationsHeader,
  usersHeader,
  wipInventoriesHeader,
  wiWithEslHeader,
  workLogHeader,
} from "@/constants/columnHeader";
import ProtectedRoute from "@/features/Router/ProtectedRoute";
import { Layout } from "@/features/layout/Template";
import { LotTrackingProvider } from "@/features/lotTracking/context/LotTrackingProvider";
import ModalExample from "@/features/modal/example";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { ContainerWithBookmark } from "@/features/standardLayout/ContainerWithBookmark";
import { StandardLayoutProvider } from "@/features/standardLayout/Provider";
import { CheckboxProvider } from "@/features/workByManagement/context/CheckboxProvider";
import { ProductionOutsourcingSlittingPage } from "@/pages/productionManagement/oursourcingSlitting";
import { useCheckAuth } from "@/utils/checkAuth";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const KPIDefectPage = React.lazy(() =>
  import("@/pages/KPI/kpi/defect").then((mod) => ({ default: mod.KPIDefectPage })),
);
const KPILeadtimePage = React.lazy(() =>
  import("@/pages/KPI/kpi/leadtime").then((mod) => ({ default: mod.KPILeadtimePage })),
);
const KPILeadTimeOrderPage = React.lazy(() =>
  import("@/pages/KPI/kpi/leadtimeOrder").then((mod) => ({ default: mod.KPILeadTimeOrderPage })),
);
const KPIManhourPage = React.lazy(() =>
  import("@/pages/KPI/kpi/manhour").then((mod) => ({ default: mod.KPIManhourPage })),
);
const KPIPowerUsagePage = React.lazy(() =>
  import("@/pages/KPI/kpi/powerUsage").then((mod) => ({ default: mod.KPIPowerUsagePage })),
);
const KPIRateOfOperationPage = React.lazy(() =>
  import("@/pages/KPI/kpi/rateOfOperation").then((mod) => ({
    default: mod.KPIRateOfOperationPage,
  })),
);
const KPIUPHPage = React.lazy(() =>
  import("@/pages/KPI/kpi/uph").then((mod) => ({ default: mod.KPIUPHPage })),
);
const AnalysisAbilityPage = React.lazy(() =>
  import("@/pages/PMS/analysis/ability").then((mod) => ({ default: mod.AnalysisAbilityPage })),
);
const AnalysisErrorPage = React.lazy(() =>
  import("@/pages/PMS/analysis/error").then((mod) => ({ default: mod.AnalysisErrorPage })),
);
const AnalysisIdleTimePage = React.lazy(() =>
  import("@/pages/PMS/analysis/idleTime").then((mod) => ({ default: mod.AnalysisIdleTimePage })),
);
const AnalysisOutputPage = React.lazy(() =>
  import("@/pages/PMS/analysis/output").then((mod) => ({ default: mod.AnalysisOutputPage })),
);
const AnalysisPowerPage = React.lazy(() =>
  import("@/pages/PMS/analysis/power").then((mod) => ({ default: mod.AnalysisPowerPage })),
);
const AnalysisTotalOutputPage = React.lazy(() =>
  import("@/pages/PMS/analysis/totaloutput").then((mod) => ({
    default: mod.AnalysisTotalOutputPage,
  })),
);
const AnalysisWorkTimePage = React.lazy(() =>
  import("@/pages/PMS/analysis/workTime").then((mod) => ({ default: mod.AnalysisWorkTimePage })),
);
const MonitoringPage = React.lazy(() =>
  import("@/pages/PMS/monitoring").then((mod) => ({ default: mod.MonitoringPage })),
);
const PressCamViewPage = React.lazy(() =>
  import("@/pages/PMS/press/camview").then((mod) => ({ default: mod.PressCamViewPage })),
);
const PressClutchAndBrakePage = React.lazy(() =>
  import("@/pages/PMS/press/clutchandbrake").then((mod) => ({
    default: mod.PressClutchAndBrakePage,
  })),
);
const PressCompleteAdminPage = React.lazy(() =>
  import("@/pages/PMS/press/completeAdmin").then((mod) => ({
    default: mod.PressCompleteAdminPage,
  })),
);
const PressErrorViewPage = React.lazy(() =>
  import("@/pages/PMS/press/errorview").then((mod) => ({ default: mod.PressErrorViewPage })),
);
const PressFacilitiesPage = React.lazy(() =>
  import("@/pages/PMS/press/facilities").then((mod) => ({ default: mod.PressFacilitiesPage })),
);
const PressParameterViewPage = React.lazy(() =>
  import("@/pages/PMS/press/parameterview").then((mod) => ({
    default: mod.PressParameterViewPage,
  })),
);
const PressDailyRegisterPage = React.lazy(() =>
  import("@/pages/PMS/press/pressDailyRegister").then((mod) => ({
    default: mod.PressDailyRegisterPage,
  })),
);
const PressDailyStatusPage = React.lazy(() =>
  import("@/pages/PMS/press/pressDailyStatus").then((mod) => ({
    default: mod.PressDailyStatusPage,
  })),
);
const PressProblemPage = React.lazy(() =>
  import("@/pages/PMS/press/problem").then((mod) => ({ default: mod.PressProblemPage })),
);
const PressMonitoringFactoryPage = React.lazy(() =>
  import("@/pages/PMS/pressMonitoring/factory").then((mod) => ({
    default: mod.PressMonitoringFactoryPage,
  })),
);
const FactoryPageSetting = React.lazy(() =>
  import("@/pages/PMS/pressMonitoring/factory/pageSetting").then((mod) => ({
    default: mod.FactoryPageSetting,
  })),
);
const AnalysisMonitoringPage = React.lazy(() =>
  import("@/pages/analysisMonitoring").then((mod) => ({ default: mod.AnalysisMonitoringPage })),
);
const BarcodeLiftPage = React.lazy(() =>
  import("@/pages/barcodeLift").then((mod) => ({ default: mod.BarcodeLiftPage })),
);
const BarcodeMergePage = React.lazy(() =>
  import("@/pages/barcodeMerge").then((mod) => ({ default: mod.BarcodeMergePage })),
);
const ChangePointPage = React.lazy(() =>
  import("@/pages/changePoint").then((mod) => ({ default: mod.ChangePointPage })),
);
const ChangePointListPage = React.lazy(() =>
  import("@/pages/changePointList").then((mod) => ({ default: mod.ChangePointListPage })),
);
const DailyListPage = React.lazy(() =>
  import("@/pages/dailyList").then((mod) => ({ default: mod.DailyListPage })),
);
const DailyRegisterPage = React.lazy(() =>
  import("@/pages/dailyRegister").then((mod) => ({ default: mod.DailyRegisterPage })),
);
const MainDashboard = React.lazy(() =>
  import("@/pages/dashboard").then((mod) => ({ default: mod.MainDashboard })),
);
const DataDisplayPage = React.lazy(() =>
  import("@/pages/dataDisplay").then((mod) => ({ default: mod.DataDisplayPage })),
);
const DeliveryListCompanyPage = React.lazy(() =>
  import("@/pages/deliveryListCompany").then((mod) => ({ default: mod.DeliveryListCompanyPage })),
);
const DeliveryListHeadOfficePage = React.lazy(() =>
  import("@/pages/deliveryListHeadOffice").then((mod) => ({
    default: mod.DeliveryListHeadOfficePage,
  })),
);
const DeliveryListSupplierPage = React.lazy(() =>
  import("@/pages/deliveryListSupplier").then((mod) => ({ default: mod.DeliveryListSupplierPage })),
);
const DeliveryStatusHeadOfficePage = React.lazy(() =>
  import("@/pages/deliveryStatusHeadOffice").then((mod) => ({
    default: mod.DeliveryStatusHeadOfficePage,
  })),
);
const DeliveryStatusSupplierPage = React.lazy(() =>
  import("@/pages/deliveryStatusSupplier").then((mod) => ({
    default: mod.DeliveryStatusSupplierPage,
  })),
);
const DownTimeReasonsLogPage = React.lazy(() =>
  import("@/pages/downtimeReasonsLog").then((mod) => ({ default: mod.DownTimeReasonsLogPage })),
);
const EquipmentAnalysisPage = React.lazy(() =>
  import("@/pages/equipmentAnalysis").then((mod) => ({ default: mod.EquipmentAnalysisPage })),
);
const EquipmentProblemPage = React.lazy(() =>
  import("@/pages/equipmentProblem").then((mod) => ({ default: mod.EquipmentProblemPage })),
);
const ErrorPage = React.lazy(() =>
  import("@/pages/error").then((mod) => ({ default: mod.ErrorPage })),
);
const ErrorViewPage = React.lazy(() =>
  import("@/pages/errorView").then((mod) => ({ default: mod.ErrorViewPage })),
);
const IdleTimePage = React.lazy(() =>
  import("@/pages/idleTime").then((mod) => ({ default: mod.IdleTimePage })),
);
const InventoryPage = React.lazy(() =>
  import("@/pages/inventoryManagement/inventory").then((mod) => ({ default: mod.InventoryPage })),
);
const NewInventory = React.lazy(() =>
  import("@/pages/inventoryManagement/inventory/NewInventory").then((mod) => ({
    default: mod.NewInventory,
  })),
);
const InventoryLifePage = React.lazy(() =>
  import("@/pages/inventoryManagement/inventoryLife").then((mod) => ({
    default: mod.InventoryLifePage,
  })),
);
const LackSafetyPage = React.lazy(() =>
  import("@/pages/inventoryManagement/lackSafety").then((mod) => ({ default: mod.LackSafetyPage })),
);
const LedgerAllPage = React.lazy(() =>
  import("@/pages/inventoryManagement/ledgersAll").then((mod) => ({ default: mod.LedgerAllPage })),
);
const WipInventoryPage = React.lazy(() =>
  import("@/pages/inventoryManagement/wipInventory").then((mod) => ({
    default: mod.WipInventoryPage,
  })),
);
const WmsLogsPage = React.lazy(() =>
  import("@/pages/inventoryManagement/wmsLogs").then((mod) => ({ default: mod.WmsLogsPage })),
);
const LabelSupplierPage = React.lazy(() =>
  import("@/pages/labelSupplier").then((mod) => ({ default: mod.LabelSupplierPage })),
);
const Login = React.lazy(() => import("@/pages/login"));
const LotsPage = React.lazy(() => import("@/pages/lot").then((mod) => ({ default: mod.LotsPage })));
const MaintenancePage = React.lazy(() =>
  import("@/pages/maintenance").then((mod) => ({ default: mod.MaintenancePage })),
);
const MaintenanceCompletePage = React.lazy(() =>
  import("@/pages/maintenanceComplete").then((mod) => ({ default: mod.MaintenanceCompletePage })),
);
const MaintenanceListPage = React.lazy(() =>
  import("@/pages/maintenanceList").then((mod) => ({ default: mod.MaintenanceListPage })),
);
const BOMListPage = React.lazy(() =>
  import("@/pages/masterData/BomList").then((mod) => ({ default: mod.BOMListPage })),
);
const AbstractItemPage = React.lazy(() =>
  import("@/pages/masterData/abstractItem").then((mod) => ({ default: mod.AbstractItemPage })),
);
const ALCModelPage = React.lazy(() =>
  import("@/pages/masterData/alcModel").then((mod) => ({ default: mod.ALCModelPage })),
);
const AuthorityPage = React.lazy(() =>
  import("@/pages/masterData/authority").then((mod) => ({ default: mod.AuthorityPage })),
);
const BOMPage = React.lazy(() =>
  import("@/pages/masterData/bom").then((mod) => ({ default: mod.BOMPage })),
);
const BOMSearchPage = React.lazy(() =>
  import("@/pages/masterData/bomSearch").then((mod) => ({ default: mod.BOMSearchPage })),
);
const CommonCodePage = React.lazy(() =>
  import("@/pages/masterData/commonCode").then((mod) => ({ default: mod.CommonCodePage })),
);
const Defects = React.lazy(() =>
  import("@/pages/masterData/defects").then((mod) => ({ default: mod.Defects })),
);
const DepartmentPage = React.lazy(() =>
  import("@/pages/masterData/department").then((mod) => ({ default: mod.DepartmentPage })),
);
const DocumentFilePage = React.lazy(() =>
  import("@/pages/masterData/documentFile").then((mod) => ({ default: mod.DocumentFilePage })),
);
const DowntimeReasonsPage = React.lazy(() =>
  import("@/pages/masterData/downtimeReasons").then((mod) => ({
    default: mod.DowntimeReasonsPage,
  })),
);
const Equipment = React.lazy(() =>
  import("@/pages/masterData/equipment").then((mod) => ({ default: mod.Equipment })),
);
const GradePage = React.lazy(() =>
  import("@/pages/masterData/grade").then((mod) => ({ default: mod.GradePage })),
);
const Item = React.lazy(() =>
  import("@/pages/masterData/item").then((mod) => ({ default: mod.Item })),
);
const ItemCategoryPage = React.lazy(() =>
  import("@/pages/masterData/itemCategory").then((mod) => ({ default: mod.ItemCategoryPage })),
);
const LocationPage = React.lazy(() =>
  import("@/pages/masterData/location").then((mod) => ({ default: mod.LocationPage })),
);
const MachinePage = React.lazy(() =>
  import("@/pages/masterData/machine").then((mod) => ({ default: mod.MachinePage })),
);
const MoldPage = React.lazy(() =>
  import("@/pages/masterData/mold").then((mod) => ({ default: mod.MoldPage })),
);
const MoldStockPage = React.lazy(() =>
  import("@/pages/masterData/moldStock").then((mod) => ({ default: mod.MoldStockPage })),
);
const Operations = React.lazy(() =>
  import("@/pages/masterData/operations").then((mod) => ({ default: mod.Operations })),
);
const PartnerPage = React.lazy(() =>
  import("@/pages/masterData/partner").then((mod) => ({ default: mod.PartnerPage })),
);
const PeripheralPage = React.lazy(() =>
  import("@/pages/masterData/peripheral").then((mod) => ({ default: mod.PeripheralPage })),
);
const Routing = React.lazy(() =>
  import("@/pages/masterData/routing").then((mod) => ({ default: mod.Routing })),
);
const RoutingOutsourcePage = React.lazy(() =>
  import("@/pages/masterData/routingOutsource").then((mod) => ({
    default: mod.RoutingOutsourcePage,
  })),
);
const Site = React.lazy(() =>
  import("@/pages/masterData/site").then((mod) => ({ default: mod.Site })),
);
const Users = React.lazy(() =>
  import("@/pages/masterData/users").then((mod) => ({ default: mod.Users })),
);
const FormExample = React.lazy(() =>
  import("@/pages/modalExample/form").then((mod) => ({ default: mod.FormExample })),
);
const WizardForm = React.lazy(() => import("@/pages/modalExample/wizard"));
const MoldCompleteAdminPage = React.lazy(() =>
  import("@/pages/moldManagement/completeForAdmin").then((mod) => ({
    default: mod.MoldCompleteAdminPage,
  })),
);
const MoldMaintenancePage = React.lazy(() =>
  import("@/pages/moldManagement/maintenance").then((mod) => ({
    default: mod.MoldMaintenancePage,
  })),
);
const MoldDailyRegisterPage = React.lazy(() =>
  import("@/pages/moldManagement/moldDailyRegister").then((mod) => ({
    default: mod.MoldDailyRegisterPage,
  })),
);
const MoldDailyStatusPage = React.lazy(() =>
  import("@/pages/moldManagement/moldDailyStatus").then((mod) => ({
    default: mod.MoldDailyStatusPage,
  })),
);
const MoldProblemPage = React.lazy(() =>
  import("@/pages/moldManagement/problem").then((mod) => ({ default: mod.MoldProblemPage })),
);
const MoldRepairsPage = React.lazy(() =>
  import("@/pages/moldManagement/repairs").then((mod) => ({ default: mod.MoldRepairsPage })),
);
const NotFound = React.lazy(() =>
  import("@/pages/notFound").then((mod) => ({ default: mod.NotFound })),
);
const WorkByOperationOutsource = React.lazy(() =>
  import("@/pages/operationOutsourceWorkByRow").then((mod) => ({
    default: mod.WorkByOperationOutsource,
  })),
);
const OrderCompletePage = React.lazy(() =>
  import("@/pages/orderComplete").then((mod) => ({ default: mod.OrderCompletePage })),
);
const OrderHeadOfficePage = React.lazy(() =>
  import("@/pages/orderHeadOffice").then((mod) => ({ default: mod.OrderHeadOfficePage })),
);
const OutputPage = React.lazy(() =>
  import("@/pages/output").then((mod) => ({ default: mod.OutputPage })),
);
const OverViewPage = React.lazy(() =>
  import("@/pages/overview").then((mod) => ({ default: mod.OverViewPage })),
);
const PowerPage = React.lazy(() =>
  import("@/pages/power").then((mod) => ({ default: mod.PowerPage })),
);
const ProductionInspectionPage = React.lazy(() =>
  import("@/pages/productionInspection").then((mod) => ({ default: mod.ProductionInspectionPage })),
);
const LotTrackingForwardPage = React.lazy(() =>
  import("@/pages/productionManagement/lotTrackingForward").then((mod) => ({
    default: mod.LotTrackingForwardPage,
  })),
);
const LotTrackingRevercePage = React.lazy(() =>
  import("@/pages/productionManagement/lotTrackingReverce").then((mod) => ({
    default: mod.LotTrackingRevercePage,
  })),
);
const WorkByEquipment = React.lazy(() =>
  import("@/pages/productionManagement/workByEquipment").then((mod) => ({
    default: mod.WorkByEquipment,
  })),
);
const WorkByProductionPlan = React.lazy(() =>
  import("@/pages/productionManagement/workByProductionPlan").then((mod) => ({
    default: mod.WorkByProductionPlan,
  })),
);
const WorkByRows = React.lazy(() =>
  import("@/pages/productionManagement/workByRows").then((mod) => ({ default: mod.WorkByRows })),
);
const WorkLogPage = React.lazy(() =>
  import("@/pages/productionManagement/workLog").then((mod) => ({ default: mod.WorkLogPage })),
);
const ProductionPerformancePage = React.lazy(() =>
  import("@/pages/productionPerformance").then((mod) => ({
    default: mod.ProductionPerformancePage,
  })),
);
const ProductionPerformanceOutsourcingPage = React.lazy(() =>
  import("@/pages/productionPerformanceOutsourcing").then((mod) => ({
    default: mod.ProductionPerformanceOutsourcingPage,
  })),
);
const PurchaseOrderItemsHeadOfficePage = React.lazy(() =>
  import("@/pages/purchaseOrderItemsHeadOffice").then((mod) => ({
    default: mod.PurchaseOrderItemsHeadOfficePage,
  })),
);
const PurchaseOrderItemsSupplierPage = React.lazy(() =>
  import("@/pages/purchaseOrderItemsSupplier").then((mod) => ({
    default: mod.PurchaseOrderItemsSupplierPage,
  })),
);
const InspectSpecificationPage = React.lazy(() =>
  import("@/pages/qualityManagement/inspect").then((mod) => ({
    default: mod.InspectSpecificationPage,
  })),
);
const InspectionRecordsPage = React.lazy(() =>
  import("@/pages/qualityManagement/inspectionRecords").then((mod) => ({
    default: mod.InspectionRecordsPage,
  })),
);
const ChangeListPage = React.lazy(() =>
  import("@/pages/qualityManagement/quality/changeList").then((mod) => ({
    default: mod.ChangeListPage,
  })),
);
const ChangeRegisterInfomationPage = React.lazy(() =>
  import("@/pages/qualityManagement/quality/changeRegister").then((mod) => ({
    default: mod.ChangeRegisterInfomationPage,
  })),
);
const DefectStatisticsPage = React.lazy(() =>
  import("@/pages/qualityManagement/quality/defectStatistics").then((mod) => ({
    default: mod.DefectStatisticsPage,
  })),
);
const WorkStandardListPage = React.lazy(() =>
  import("@/pages/qualityManagement/quality/workStandardList").then((mod) => ({
    default: mod.WorkStandardListPage,
  })),
);
const ReceiptPage = React.lazy(() =>
  import("@/pages/receipt").then((mod) => ({ default: mod.ReceiptPage })),
);
const ReceivingInspectionPage = React.lazy(() =>
  import("@/pages/receivingInspection").then((mod) => ({ default: mod.ReceivingInspectionPage })),
);
const DeliveryStatusPage = React.lazy(() =>
  import("@/pages/salesManagement/deliveryStatus").then((mod) => ({
    default: mod.DeliveryStatusPage,
  })),
);
const InvoiceStatementCompletePage = React.lazy(() =>
  import("@/pages/salesManagement/invoiceComplete").then((mod) => ({
    default: mod.InvoiceStatementCompletePage,
  })),
);
const InvoiceStatementPage = React.lazy(() =>
  import("@/pages/salesManagement/invoiceStatement").then((mod) => ({
    default: mod.InvoiceStatementPage,
  })),
);
const OrderPage = React.lazy(() =>
  import("@/pages/salesManagement/order").then((mod) => ({ default: mod.OrderPage })),
);
const OrderItemsPage = React.lazy(() =>
  import("@/pages/salesManagement/orderItems").then((mod) => ({ default: mod.OrderItemsPage })),
);
const PurchaseOrderPage = React.lazy(() =>
  import("@/pages/salesManagement/purchaseOrder").then((mod) => ({
    default: mod.PurchaseOrderPage,
  })),
);
const PurchaseOrderItemsPage = React.lazy(() =>
  import("@/pages/salesManagement/purchaseOrderItems").then((mod) => ({
    default: mod.PurchaseOrderItemsPage,
  })),
);
const StockPlanPage = React.lazy(() =>
  import("@/pages/salesManagement/stockPlan").then((mod) => ({ default: mod.StockPlanPage })),
);
const TransportationPage = React.lazy(() =>
  import("@/pages/salesManagement/transportation").then((mod) => ({
    default: mod.TransportationPage,
  })),
);
const ShipmentReturnPage = React.lazy(() =>
  import("@/pages/shipmentReturn").then((mod) => ({ default: mod.ShipmentReturnPage })),
);
const ShipmentReceiptPage = React.lazy(() =>
  import("@/pages/stockManagement/shipmentReceipt").then((mod) => ({
    default: mod.ShipmentReceiptPage,
  })),
);
const ShipmentRequestPage = React.lazy(() =>
  import("@/pages/stockManagement/shipmentRequest").then((mod) => ({
    default: mod.ShipmentRequestPage,
  })),
);
const ShipmentStatusPage = React.lazy(() =>
  import("@/pages/stockManagement/shipmentStatus").then((mod) => ({
    default: mod.ShipmentStatusPage,
  })),
);
const SystemPage = React.lazy(() =>
  import("@/pages/systemManagement/system").then((mod) => ({ default: mod.SystemPage })),
);
const SystemLogsPage = React.lazy(() =>
  import("@/pages/systemManagement/systemLogs").then((mod) => ({ default: mod.SystemLogsPage })),
);
const SystemParameterPage = React.lazy(() =>
  import("@/pages/systemManagement/systemParameter").then((mod) => ({
    default: mod.SystemParameterPage,
  })),
);
const StandardInfoBestPracticePage = React.lazy(() =>
  import("@/pages/test/StandardInfoBestPractice").then((mod) => ({
    default: mod.StandardInfoBestPracticePage,
  })),
);
const UserLogsPage = React.lazy(() =>
  import("@/pages/userLogs").then((mod) => ({ default: mod.UserLogsPage })),
);
const WarehousingRegisterPage = React.lazy(() =>
  import("@/pages/warehousingResigter").then((mod) => ({ default: mod.WarehousingRegisterPage })),
);
const WorkCompletePage = React.lazy(() =>
  import("@/pages/workComplete").then((mod) => ({ default: mod.WorkCompletePage })),
);
const WorkStandardPage = React.lazy(() =>
  import("@/pages/workStandard").then((mod) => ({ default: mod.WorkStandardPage })),
);
const WorkTimePage = React.lazy(() =>
  import("@/pages/workTime").then((mod) => ({ default: mod.WorkTimePage })),
);

// PMS 메뉴 Routes
const pmsMenuRoutes = [
  { path: "/analysis/output", element: <AnalysisOutputPage /> },
  { path: "/analysis/ability", element: <AnalysisAbilityPage /> },
  { path: "/analysis/totaloutput", element: <AnalysisTotalOutputPage /> },
  { path: "/analysis/error", element: <AnalysisErrorPage /> },
  { path: "/analysis/power", element: <AnalysisPowerPage /> },
  { path: "/analysis/idleTime", element: <AnalysisIdleTimePage /> },
  { path: "/analysis/workTime", element: <AnalysisWorkTimePage /> },
  { path: "/press/errorview", element: <PressErrorViewPage /> },
  { path: "/press/parameterview", element: <PressParameterViewPage /> },
  { path: "/press/camview", element: <PressCamViewPage /> },
  { path: "/press/clutchandbrake", element: <PressClutchAndBrakePage /> },
  { path: "/press/facilities", element: <PressFacilitiesPage /> },
  { path: "/press/complete/admin", element: <PressCompleteAdminPage /> },
  { path: "/press/problem", element: <PressProblemPage /> },
  { path: "/press/PressDailyStatus", element: <PressDailyStatusPage /> },
  { path: "/press/PressDailyRegister", element: <PressDailyRegisterPage /> },
  { path: "/mold/maintenance", element: <MoldMaintenancePage /> },
  { path: "/mold/repairs", element: <MoldRepairsPage /> },
  { path: "/mold/complete/admin", element: <MoldCompleteAdminPage /> },
  { path: "/mold/problem", element: <MoldProblemPage /> },
  { path: "/mold/moldDailyRegister", element: <MoldDailyRegisterPage /> },
  { path: "/mold/moldDailyStatus", element: <MoldDailyStatusPage /> },
];

// KPI 메뉴 Routes
const kpiMenuRoutes = [
  { path: "/kpi/leadtime", element: <KPILeadtimePage /> },
  { path: "/kpi/manhour", element: <KPIManhourPage /> },
  { path: "/kpi/defect", element: <KPIDefectPage /> },
  { path: "/kpi/leadtimeOrder", element: <KPILeadTimeOrderPage /> },
  { path: "/kpi/powerUsage", element: <KPIPowerUsagePage /> },
  { path: "/kpi/uph", element: <KPIUPHPage /> },
  { path: "/kpi/rateOfOperation", element: <KPIRateOfOperationPage /> },
];

// 입출고관리 메뉴 Routes
const storageManagementMenuRoutes = [
  { path: "/shipment-request", element: <ShipmentRequestPage /> },
  { path: "/shipment-receipt", element: <ShipmentReceiptPage /> },
  { path: "/shipment-status", element: <ShipmentStatusPage /> },
];

// 설비모니터링 메뉴 Routes
const equipmentMonitoringRoutes = [
  { path: "/analysisMonitoring", element: <AnalysisMonitoringPage /> },
  { path: "/errorView", element: <ErrorViewPage /> },
  { path: "/maintenance", element: <MaintenancePage /> },
  { path: "/maintenanceList", element: <MaintenanceListPage /> },
  { path: "/maintenanceComplete", element: <MaintenanceCompletePage /> },
  { path: "/equipmentProblem", element: <EquipmentProblemPage /> },
  { path: "/dailyRegister", element: <DailyRegisterPage /> },
  { path: "/dailyList", element: <DailyListPage /> },
  { path: "/output", element: <OutputPage /> },
  { path: "/error", element: <ErrorPage /> },
  { path: "/power", element: <PowerPage /> },
  { path: "/idleTime", element: <IdleTimePage /> },
  { path: "/workTime", element: <WorkTimePage /> },
];
// 시스템 메뉴 Routes
const systemMenuRoutes = [
  { path: "/system", element: <SystemPage /> },
  { path: "/system-logs", element: <SystemLogsPage /> },
  { path: "/system-parameter", element: <SystemParameterPage /> },
];

const RoutePaths = [
  { path: "/dashboard", element: <MainDashboard /> },
  ...(customFunctions.ADD_USER_ACCESS_STATUS
    ? [{ path: "/userLogs", element: <UserLogsPage /> }]
    : []),
  { path: "/work-by-equipment", element: <WorkByEquipment /> },
  { path: "/work-by-production-plan", element: <WorkByProductionPlan /> },
  { path: "/work-by-row", element: <WorkByRows /> },
  ...(customFunctions.ADD_SHOW_OPERATION_OUTSOURCING_WORK
    ? [
        {
          path: "/operation-outsource-work-by-row",
          element: (
            <CheckboxProvider>
              <WorkByOperationOutsource />
            </CheckboxProvider>
          ),
        },
      ]
    : []),
  {
    path: "/productionOutsourcingSlitting",
    element: (
      <CheckboxProvider>
        <ProductionOutsourcingSlittingPage />
      </CheckboxProvider>
    ),
  },
  {
    path: "/lotForward",
    element: (
      <LotTrackingProvider>
        <LotTrackingForwardPage />
      </LotTrackingProvider>
    ),
  },
  {
    path: "/lotReverce",
    element: (
      <LotTrackingProvider>
        <LotTrackingRevercePage />
      </LotTrackingProvider>
    ),
  },
  { path: "/production-plan-with-works-form", element: <ProductionPlanCreateForm /> },
  ...(customFunctions.ADD_MONITORING_MENU_SETTING ||
  customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING
    ? [{ path: "/overview", element: <OverViewPage /> }]
    : []),
  ...(customFunctions.ADD_MONITORING_MENU_SETTING ||
  customFunctions.ADD_PMS_MENU_SETTING ||
  customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING
    ? [{ path: "/monitoring", element: <MonitoringPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING
    ? [{ path: "/common-code", element: <CommonCodePage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING
    ? [{ path: "/authority", element: <AuthorityPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING
    ? [{ path: "/ALCmodel", element: <ALCModelPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING
    ? [{ path: "/bom", element: <BOMPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING
    ? [{ path: "/bomList", element: <BOMListPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_FILE_MENU_SETTING
    ? [{ path: "/document-file", element: <DocumentFilePage /> }]
    : []),
  { path: "/downtimeReasons-log", element: <DownTimeReasonsLogPage /> },
  ...(customFunctions.ADD_LACK_SAFETY_MENU_SETTING
    ? [{ path: "/lackSafety", element: <LackSafetyPage /> }]
    : []),
  ...(customFunctions.ADD_INVENTORY_LIFE_MENU_SETTING
    ? [{ path: "/inventoryLife", element: <InventoryLifePage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING
    ? [{ path: "/peripheral", element: <PeripheralPage /> }]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_MACHINE_MENU_SETTING
    ? [{ path: "/machine", element: <MachinePage /> }]
    : []),
  ...(customFunctions.ADD_PMS_PRESS_MONITORING || customFunctions.ADD_PMS_MENU_SETTING
    ? [{ path: "/pressMonitoring/factoryMonitoring", element: <PressMonitoringFactoryPage /> }]
    : []),
  { path: "/pressMonitoring/factoryMonitoring/pageSetting", element: <FactoryPageSetting /> },
  ...(customFunctions.ADD_DATA_DISPLAY_MENU_SETTING && customFunctions.ADD_KPI_MENU_SETTING
    ? [{ path: "/dataDisplay", element: <DataDisplayPage /> }]
    : []),
  ...(customFunctions.ADD_DASHBOARD_EQUIPMENT_ANALYSIS
    ? [{ path: "/equipmentAnalysis", element: <EquipmentAnalysisPage /> }]
    : []),
  ...(customFunctions.ADD_DEFECT_STATISTICS_MENU_SETTING
    ? [{ path: "/quality/defect-statistics", element: <DefectStatisticsPage /> }]
    : []),
  ...(customFunctions.ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING
    ? [{ path: "/quality/work-standardList", element: <WorkStandardListPage /> }]
    : []),
  ...(customFunctions.ADD_CHANGE_LIST_MENU_SETTING
    ? [{ path: "/quality/change-list", element: <ChangeListPage /> }]
    : []),
  { path: "/quality/change-register", element: <ChangeRegisterInfomationPage /> },
  // { path: "/quality/pre-warehouseList", element: <PreWareHouseListPage /> },
  ...(customFunctions.ADD_PERFORMANCE_MENU_SETTING
    ? [{ path: "/productionperformance", element: <ProductionPerformancePage /> }]
    : []),
  ...(customFunctions.ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING
    ? [
        {
          path: "/productionperformanceOutsourcing",
          element: <ProductionPerformanceOutsourcingPage />,
        },
      ]
    : []),
  ...(customFunctions.ADD_PMS_MENU_SETTING ? pmsMenuRoutes : []),
  ...(customFunctions.ADD_KPI_MENU_SETTING ? kpiMenuRoutes : []),
  ...(customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING ? equipmentMonitoringRoutes : []),
  ...(customFunctions.ADD_SYSTEM_MANAGEMENT_MENU_SETTING ? systemMenuRoutes : []),
  ...(customFunctions.ADD_STORAGE_MANAGEMENT_MENU_SETTING ? storageManagementMenuRoutes : []),

  { path: "/wizard", element: <WizardForm /> },
  { path: "/form", element: <FormExample /> },
  { path: "/modal-multi", element: <ModalExample /> },

  { path: "/purchaseOrderItems-head-office", element: <PurchaseOrderItemsHeadOfficePage /> },
  { path: "/deliveryStatus-head-office", element: <DeliveryStatusHeadOfficePage /> },
  { path: "/deliveryList-company", element: <DeliveryListCompanyPage /> },
  { path: "/deliveryList-head-office", element: <DeliveryListHeadOfficePage /> },
  { path: "/order-head-office", element: <OrderHeadOfficePage /> },
  { path: "/purchaseOrderItems-supplier", element: <PurchaseOrderItemsSupplierPage /> },
  { path: "/deliveryStatus-supplier", element: <DeliveryStatusSupplierPage /> },
  { path: "/deliveryList-supplier", element: <DeliveryListSupplierPage /> },

  { path: "/shipment-request-supplier", element: <ShipmentRequestPage /> },
  { path: "/shipment-status-supplier", element: <ShipmentStatusPage /> },

  { path: "/label-supplier", element: <LabelSupplierPage /> },
  { path: "/shipment-return", element: <ShipmentReturnPage /> },
  { path: "/work-standard", element: <WorkStandardPage /> },
  { path: "/changePoint", element: <ChangePointPage /> },
  { path: "/changePointList", element: <ChangePointListPage /> },
  { path: "/receivingInspection", element: <ReceivingInspectionPage /> },
  { path: "/productionInspection", element: <ProductionInspectionPage /> },
  { path: "/work-complete", element: <WorkCompletePage /> },
  { path: "/receipt", element: <ReceiptPage /> },
  { path: "/barcodeLift", element: <BarcodeLiftPage /> },
  { path: "/barcodeMerge", element: <BarcodeMergePage /> },
  { path: "/warehousingRegister", element: <WarehousingRegisterPage /> },
];

// 기준정보 메뉴 Paths
const StandardInfoPath = [
  {
    path: "/items",
    element: <Item />,
    queryKeyFactory: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
      ? items.getView
      : items.get,
    searchFieldsHeader: itemsHeader,
  },
  {
    path: "/equipment",
    element: <Equipment />,
    queryKeyFactory: equipments.get,
    searchFieldsHeader: equipmentsHeader,
    populate: ["works", "location", "workerGroupArray"],
  },
  {
    path: "/defects",
    element: <Defects />,
    queryKeyFactory: defects.get,
    searchFieldsHeader: defectsHeader,
  },
  {
    path: "/operations",
    element: <Operations />,
    queryKeyFactory: operations.get,
    searchFieldsHeader: operationsHeader,
    populate: ["location"],
  },
  {
    path: "/routing",
    element: <Routing />,
    queryKeyFactory: routings.get,
    searchFieldsHeader: routingsHeader,
    populate: ["item", "operation", "mold"],
  },
  {
    path: "/users",
    element: <Users />,
    queryKeyFactory: users.get,
    searchFieldsHeader: usersHeader,
    populate: ["locationCode"],
  },
  {
    path: "/site",
    element: <Site />,
    queryKeyFactory: sites.get,
    searchFieldsHeader: sitesHeader,
  },
  {
    path: "/location",
    element: <LocationPage />,
    queryKeyFactory: locations.get,
    searchFieldsHeader: locationsHeader,
  },
  {
    path: "/routing-outsource",
    element: <RoutingOutsourcePage />,
    queryKeyFactory: routingOutsources.get,
    searchFieldsHeader: routingOutsourcesHeader,
    populate: ["location"],
  },
  {
    path: "/mold",
    element: <MoldPage />,
    queryKeyFactory: molds.get,
    searchFieldsHeader: moldsHeader,
  },
  {
    path: "/moldStock",
    element: <MoldStockPage />,
    queryKeyFactory: moldStocks.get,
    searchFieldsHeader: moldStocksHeader,
  },
  {
    path: "/abstract-item",
    element: <AbstractItemPage />,
    queryKeyFactory: abstractItems.get,
    searchFieldsHeader: abstractItemsHeader,
  },
  {
    path: "/item-category",
    element: <ItemCategoryPage />,
    queryKeyFactory: itemCategories.get,
    searchFieldsHeader: itemCategoriesHeader,
  },
  {
    path: "/downtimeReasons",
    element: <DowntimeReasonsPage />,
    queryKeyFactory: downtimeReasons.get,
    searchFieldsHeader: downtimeReasonsHeader,
  },
  ...(customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
    ? [
        {
          path: "/grade",
          element: <GradePage />,
          queryKeyFactory: grades.get,
          searchFieldsHeader: gradesHeader,
        },
      ]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING
    ? [
        {
          path: "/department",
          element: <DepartmentPage />,
          queryKeyFactory: department.get,
          searchFieldsHeader: departmentHeader,
        },
      ]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING
    ? [
        {
          path: "/partner",
          element: <PartnerPage />,
          queryKeyFactory: partners.get,
          searchFieldsHeader: partnersHeader,
        },
      ]
    : []),
  ...(customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING
    ? [
        {
          path: "/bomSearch",
          element: <BOMSearchPage />,
          queryKeyFactory: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING
            ? items.getView
            : items.get,
          searchFieldsHeader: itemsBomHeader,
        },
      ]
    : []),

  // 추가 라우트 정의 ...
  ...(customFunctions.ADD_BEST_PRACTICE_TEST_MENU_SETTING
    ? [
        {
          path: "/test-standard-info-best-practice",
          element: <StandardInfoBestPracticePage />,
          queryKeyFactory: items.get,
          searchFieldsHeader: itemsHeader,
        },
      ]
    : []),
  // 추가 라우트 정의 ...
];

const ProductionManagementPath = [
  {
    path: "/work-log",
    element: <WorkLogPage />,
    queryKeyFactory: workLogsWorks.get,
    searchFieldsHeader: workLogHeader,
    populate: [
      "itemUnit",
      "itemData",
      "routing",
      "defectName",
      "alreadyCanceled",
      "creatorUser",
      "downtimeReasonName",
      "getEquipment",
      "currentLotSummary",
    ],
    isUseDate: true,
  },
];

const InventoryControlPath = [
  {
    path: "/example",
    element: <NewInventory />,
    queryKeyFactory: inventories.betweenGet,
    searchFieldsHeader: inventoriesHeader,
    populate: ["lot", "location", "item"],
    isUseParams: true,
    isUseDate: true,
  },
  {
    path: "/inventory",
    element: <InventoryPage />,
    queryKeyFactory: inventories.betweenGet,
    searchFieldsHeader: inventoriesHeader,
    populate: ["lot", "location", "item"],
    isUseParams: true,
    isUseDate: true,
  },
  {
    path: "/ledgersAll",
    element: <LedgerAllPage />,
    queryKeyFactory: ledgers.get,
    searchFieldsHeader: LedgersHeader,
    populate: ["item", "location", "name", "item"],
    isUseDate: true,
  },
  {
    path: "/wipInventory",
    element: <WipInventoryPage />,
    queryKeyFactory: wipInventory.get,
    searchFieldsHeader: wipInventoriesHeader,
    populate: ["productionPlans", "itemUnit"],
  },
  {
    path: "/wmsLogs",
    element: <WmsLogsPage />,
    queryKeyFactory: wiWithEsl.get,
    searchFieldsHeader: wiWithEslHeader,
    populate: ["locationName", "lot", "item"],
    isUseDate: true,
  },
  {
    path: "/lots",
    element: <LotsPage />,
    queryKeyFactory: lots.get,
    searchFieldsHeader: lotsHeader,
    populate: [],
  },
];

const SalesManagementPath = [
  {
    path: "/purchaseOrder",
    element: <PurchaseOrderPage />,
    queryKeyFactory: purchaseOrders.get,
    searchFieldsHeader: purchaseOrderHeader,
    populate: ["location", "user", "partner", "purchaseOrderItems"],
  },
  {
    path: "/purchaseOrderItems",
    element: <PurchaseOrderItemsPage />,
    queryKeyFactory: purchaseOrderItems.get,
    searchFieldsHeader: purchaseOrderItemsHeader,
    populate: [
      "itemName",
      "user",
      "quantities",
      "location",
      "partner",
      "purchaseOrderCode",
      "purchaseOrder",
    ],
  },
  {
    path: "/deliveryStatus",
    element: <DeliveryStatusPage />,
    queryKeyFactory: stockPlans.wmsPartner,
    searchFieldsHeader: purchaseOrderItemsHeader,
    populate: ["locationName", "purchaseOrderItem", "lot"],
    isUseParams: true,
  },
  // 발주 완료 페이지
  ...(customFunctions.ADD_ORDER_COMPLETE_MENU_SETTING
    ? [
        {
          path: "/orderComplete",
          element: <OrderCompletePage />,
          queryKeyFactory: purchaseOrders.complete,
          searchFieldsHeader: orderHeader,
          populate: ["location", "user", "partner", "purchaseOrderItems"],
        },
      ]
    : []),
  // 발주 페이지
  {
    path: "/order",
    element: <OrderPage />,
    queryKeyFactory: purchaseOrders.order,
    searchFieldsHeader: orderHeader,
    populate: [
      "location",
      "user",
      "partner",
      "purchaseOrderItems",
      "purchaseOrderItemQty",
      "inputEligibleQuantity",
    ],
  },
  ...(customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING
    ? [
        // 거래명세서 페이지
        {
          path: "/invoiceStatement",
          element: <InvoiceStatementPage />,
          queryKeyFactory: invoice.get,
          searchFieldsHeader: invoiceStatementHeader,
          populate: [
            "populateLocationName",
            "populateUserName",
            "populateNumberOfInvoiceItems",
            "invoiceItems",
          ],
        },
        // 거래명세서 완료 페이지
        {
          path: "/invoiceStatementComplete",
          element: <InvoiceStatementCompletePage />,
          queryKeyFactory: invoice.complete,
          searchFieldsHeader: invoiceStatementHeader,
          populate: [
            "populateLocationName",
            "populateUserName",
            "populateNumberOfInvoiceItems",
            "invoiceItems",
          ],
        },
      ]
    : []),
  {
    path: "/orderItems",
    element: <OrderItemsPage />,
    queryKeyFactory: purchaseOrderItems.get,
    searchFieldsHeader: purchaseOrderItemsHeader, // 임시
    populate: [
      "itemName",
      "user",
      "quantities",
      "location",
      "partner",
      "purchaseOrderCode",
      "purchaseOrderItemQty",
      "inputEligibleQuantity",
      "purchaseOrder",
    ],
  },
  {
    path: "/stockPlan",
    element: <StockPlanPage />,
    queryKeyFactory: stockPlans.get,
    searchFieldsHeader: purchaseOrderItemsHeader,
  },
  {
    path: "/transportation",
    element: <TransportationPage />,
    queryKeyFactory: transportations.get,
    searchFieldsHeader: transportationsHeader,
  },
];

const QualityControlPath = [
  ...(customFunctions.ADD_QUALITY_MENU_SETTING
    ? [
        {
          path: "/inspectSpecification",
          element: <InspectSpecificationPage />,
          queryKeyFactory: specifications.get,
          searchFieldsHeader: specificationsHeader,
          populate: ["lot"],
        },
      ]
    : []),
  ...(customFunctions.ADD_QUALITY_MENU_SETTING
    ? [
        {
          path: "/inspectionRecords",
          element: <InspectionRecordsPage />,
          queryKeyFactory: inspections.get,
          searchFieldsHeader: specificationsHeader,
          populate: ["lot"],
        },
      ]
    : []),
];

function RoutesConfig() {
  const location = useLocation();
  const auth = useCheckAuth();

  return (
    <Routes location={location}>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/dashboard"}>
              <Login />
            </ProtectedRoute>
          }
        />
        {RoutePaths.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
              </ProtectedRoute>
            }
          />
        ))}
        {StandardInfoPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                >
                  <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {QualityControlPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                >
                  <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {InventoryControlPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                  isUseParams={route.isUseParams}
                  isUseDate={route.isUseDate}
                >
                  {" "}
                  <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {SalesManagementPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                >
                  <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        {ProductionManagementPath.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                <StandardLayoutProvider
                  key={route.path}
                  populate={route.populate}
                  queryKeyFactory={route.queryKeyFactory}
                  searchFieldsHeader={route.searchFieldsHeader}
                  isUseDate={route.isUseDate}
                >
                  <ContainerWithBookmark>{route.element}</ContainerWithBookmark>
                </StandardLayoutProvider>
              </ProtectedRoute>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default RoutesConfig;
